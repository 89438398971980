<template>
  <div class="relative">
    <a class="anchor" id="plano-estrategico"></a>
    <section class="plano-estrategico cover">
      <img src="@/assets/plano-estrategico/ellipse.svg" class="ellipse" />
      <div class="revealer">
        <h4>SOCIAL JUSTICE</h4>
        <h1>Encounter(s)</h1>
        <h1>With Impact</h1>
      </div>
    </section>

    <section class="intro flex">
      <div class="w-50 flex jc-c ai-c">
        <div class="intro-text w-70 revealer">
          <p>
            The Encounter(s) with Impact initiative aims at strengthening
            relevant social innovation and entrepreneurship projects and
            increasing their impact by building bridges between the private and
            social sectors.
          </p>
          <p>
            Taking advantage of its experience in providing legal services to
            some of the corporate sector’s biggest players and in supporting
            organisations from the social sector, VdA, together with the VVA
            Foundation, decided to bring these two distant worlds closer
            together by fostering meaningful collaborations based on a threefold
            program, which includes a mentoring journey, training phase and
            final event.
          </p>
        </div>
      </div>
      <div class="w-50 flex jc-c ai-c">
        <h2 class="quotes">“</h2>
        <h2>
          Bring these two distant worlds closer together by fostering meaningful
          collaborations
        </h2>
      </div>
    </section>

    <img src="@/assets/ft/assets_girl.png" id="plano-estrategico" alt="" />

    <section class="intro flex">
      <div class="w-50 flex jc-c ai-c">
        <div class="intro-text w-70 revealer">
          <p>
            During the mentoring phase, selected social organisations were
            connected to successful corporate professionals, benefitting from
            their mentors’ multiple talents to boost their projects and achieve
            their goals. In the second phase, the most promising projects were
            selected to attend workshops and specific training sessions on
            topics relevant to the development of their projects and potential
            impact.
          </p>
          <p>
            At this stage, the social entrepreneurs behind the selected projects
            were connected to a group of CEOs and other C-Level professionals
            who offered their knowledge and advice. Finally, an invitation-only
            event was held by VdA and the VVA Foundation, at which each team
            presented their pitch to the jury and group of CEOs and other
            C-Level professionals. This event offered the participating teams
            the opportunity to receive a prize from the VVA Foundation, to
            reinforce their network, explore new partnerships and further
            strengthen their social innovation projects.
          </p>
        </div>
      </div>
      <div class="w-50 flex jc-c ai-c">
        <h2 class="quotes">“</h2>
        <h2>
          Reinforce their network, explore new partnerships and further
          strengthen their social innovation projects.
        </h2>
      </div>
    </section>

    <video autoplay muted loop id="planoVideo">
      <source src="./../assets/plano-estrategico/discos.mp4" type="video/mp4" />
    </video>

    <!-- TEAM -->

    <!-- <a class="anchor" id="fullTeam"></a>
    <section class="projetos flex h-60" >
    <h1 class="revealer team">The Team</h1>
      <div class="projetos-slider flex">
        <div v-for="(item,i) of itemsTeam" :key="i" class="slide flex jc-sb w-100" :style="{transform: `translateX(${value}%)`}">
          <div class="w-100 h-60 flex jc-c ai-c">
            <div class="team flex col ai-c jc-c">
              <div v-for="(row,k) of item.team" :key="k" class="team-row flex jc-sb" :class="[k == 'firstTeam' ? 'firstTeam' : 'secondTeam', row.length == 3 ? 'w-80' : 'w-100']" >
                <div v-for="person of row" :key="person.img" class="team-col flex col ai-c jc-fe">
                  <img :src="`media/fullteam/${person.img}.png`" :id="person.img" />
                  <p>{{person.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "Card1",
  data() {
    return {
      items: [
        {
          title: "Conselho Estratégico",
          first:
            "O Conselho Estratégico é um polo de partilha de conhecimento na área da inovação, com o objetivo de facilitar a identificação de novas tendências e oportunidades, através do debate de estratégias e da proposta de atividades que promovam a inovação.",
          second:
            "O Conselho é liderado pelo João Vieira de Almeida e dinamizado pela Professora Céline Abecassis-Moedas e integra players relevantes de vários sectores, incluindo os sectores empresarial, académico e tecnológico."
        },
        {
          title: "VdA Inovação",
          first:
            "A VdA Inovação é responsável pela implementação da estratégia de inovação da Firma, pelo acompanhamento do desenvolvimento de soluções inovadoras, pela dinamização de iniciativas com o objetivo de identificar oportunidades de inovação e desenvolver produtos jurídicos novos, escaláveis, replicáveis e com valor para os clientes.",
          second:
            "A equipa da VdA Inovação inclui os seguintes membros: Paulo de Barros Baptista (Chairman), Benedita Aires (Head of Innovation), Joaquim Pedro Lampreia, Margarida Saragoça, Muriel Faden da Silva, Filipe Rocha Vieira, Nuno Carrolo, Teresa Teixeira Mota, Iakovina Kindylidi, Filipa Correia e Rebeca McLeod."
        },
        {
          title: "Rede de i9",
          first:
            "A rede de i9s patrocina, em articulação com a VdA Inovação, desafios e projetos de inovação na Firma. Em particular, os i9 têm como missão dinamizar a inovação ao nível dos Grupos, Direções e da rede VdA Legal Partners.",
          second:
            "Cada i9 é responsável por definir, em articulação com os GEP, RAPs ou Diretores, os objetivos de inovação para o seu Grupo, Direção ou rede, estimular o desenvolvimento de projetos de inovação e dinamizar os Law Innovation Labs. Há um i9 em cada Grupo, dois nas áreas de gestão e um dedicado à rede VdA Legal Partners. Ser um i9 é uma oportunidade única para contribuir para o sucesso da Firma, desafiando o futuro e dinamizando a criação de soluções diferenciadoras."
        },
        {
          title: "Law Innovation Labs",
          first:
            "Os Law Innovation Labs têm por objetivo facilitar a identificação de oportunidades e o desenvolvimento de soluções ou iniciativas inovadoras pelas áreas de prática, áreas de gestão ou rede VdA Legal Partners.",
          second:
            "Os Law Innovation Labs são coordenados pelos i9, em articulação com a VdA Inovação e com o apoio direto dos Group Executive Partners – GEPs e existirão 9 Law Innovation Labs (um por cada Grupo, dois nas Direções e um na rede VdA Legal Partners). Os GEPs têm um papel essencial na dinamização dos Labs e na definição dos objetivos de inovação do respetivo grupo, em coordenação com os responsáveis de área."
        },
        {
          title: "VdA Innovation Hub",
          first:
            "O VdA Innovation Hub tem como objetivo fomentar a partilha de informação sobre projetos inovadores ou diferenciadores e criar sinergias entre diferentes projetos e iniciativas existentes na Firma.",
          second:
            "O Hub é presidido pelo João Vieira de Almeida e junta trimestralmente os responsáveis por diferentes projetos e iniciativas com impacto no projeto de inovação da Firma."
        }
      ]
      // itemsTeam: [
      //   {
      //     title: 'Projeto Client Focus',
      //     desc: 'Solução destinada a fidelizar clientes pontuais estratégicos, transformando-os em clientes de continuidade, através da criação de client relationship teams e de uma plataforma interna de recolha/divulgação de know-how e informação relativa àqueles clientes, respetivo negócio e sectores de atividade.',
      //     team: {
      //       firstTeam: [
      //         { name: 'Benedita Aires', img: 'benedita-aires'},
      //         { name: 'Joaquim Pedro Lampreia', img: 'joaquim-pedro-lampreia'},
      //         { name: 'Margarida Saragoça', img: 'margarida-saragoca'},
      //         { name: 'Muriel Faden da Silva', img: 'muriel-faden-da-silva'},
      //         { name: 'Filipe Rocha Vieira', img: 'filipe-rocha-vieira'},
      //       ],
      //     }
      //   },
      // ]
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";

.plano-estrategico {
  background-color: black;
  // background-image: url(./../assets/plano-estrategico/bg-plano.png);

  img {
    position: absolute;
    top: 50%;
    left: -12%;
    height: 90%;
    transform: translateY(-50%);
  }

  div {
    position: absolute;
    bottom: 20%;
  }
}

.intro {
  &-text {
    font-size: 18px;
    line-height: 30px;
    p:first-of-type {
      margin-bottom: 30px;
    }
  }

  &-media video {
    height: 400px;
  }
}

.visao {
  &-text {
    font-size: 16px;
    line-height: 26px;
    margin: 20px 0;
  }

  &-media video {
    height: 600px;
    margin-top: 40px;
  }
}

.estrutura {
  .item {
    padding: 80px 0;
  }

  .changeOrder {
    order: -1;
  }

  .item-text p {
    font-size: 18px;
    line-height: 26px;
  }

  .first {
    margin: 20px 0;
  }
}

.inovacao {
  &-text {
    font-size: 16px;
    line-height: 26px;
    margin: 30px 0;
  }

  .subtitle {
    font-family: "Internacional-Black";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
  }

  &-media {
    margin-top: 20px;
  }
}

h4 {
  color: white;
}

img {
  width: 100%;
  overflow-x: auto;
  height: 20%;
}

.ai-c {
  width: 100%;
}

#videoFull {
  padding: 0;
  width: 100%;
}

.visao {
  min-height: 15vh !important;
}

/*VIDEO*/
.relative {
  position: relative;
}

#planoVideo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.plano-estrategico {
  z-index: 1;
  background-color: transparent;
}

/*ANIMAÇÕES*/
.revealed h1 {
  animation: fadeInUp;
  animation-duration: 2s;
}

.revealed h2 {
  animation: fadeInUp;
  animation-duration: 2s;
}

.revealed p {
  animation: fadeInUp;
  animation-duration: 2s;
}

.revealed h3 {
  animation: fadeInDown;
  animation-duration: 2s;
}

.revealed .changeOrderImg {
  animation: fadeInDown;
  animation-duration: 2s;
}

/*REPONSIVE*/
@media only screen and (max-width: 600px) {
  section {
    overflow-x: hidden;
  }

  div {
    bottom: 0%;
  }

  img {
    padding-top: 40px;
    width: 180%;
    float: right;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
    width: 100%;
    justify-content: left;
  }

  .plano-estrategico {
    z-index: 1;
    // background-color: transparent;
  }

  .w-50 {
    width: 100%;
    flex-direction: column;
  }

  .flex {
    flex-direction: column;
  }

  .intro-media video {
    width: 90%;
    margin: 50px;
  }

  .intro-text {
    margin-top: 50px;
  }

  .visao-media video {
    width: 90%;
    margin: 50px;
    height: auto;
  }

  .visao {
    min-height: auto !important;
  }

  .changeOrder {
    order: 0 !important;
  }

  .changeOrderImg {
    max-width: 80%;
    margin-top: 50px;
  }

  .inovacao .w-60 {
    width: 90% !important;
  }
}
</style>
