import Vue from "vue";
import VueRouter from "vue-router";

import Slider from "../components/Slider.vue";
import Card1 from "../components/Card1.vue";
import Hello from "../components/Hello.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Slider",
    component: Slider
  },
  {
    path: "/card1",
    name: "Card1",
    component: Card1
  },
  {
    path: "/hello",
    name: "Hello",
    component: Hello
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
