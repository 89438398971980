<template>
  <div>
    <header class="fixed flex jc-sb">
      <div class="nav-logo flex ai-c">
        <!-- <a href="#" class="flex ai-c"></a> -->
        <img
          src="@/assets/navbar/logo-VDA.png"
          alt="Logotipo Vieira de Almeida"
        />
      </div>
    <!-- <div class="nav-search"> -->
      <!-- <input autocomplete="off" @click="seen = !seen" v-model="search" id="search" placeholder="Voltar ao Menu" aria-label="Pesquise pelo conteúdo do site"> -->
      <v-btn 
        id="back"
        v-if="$route.path != '/'"
        >
        <router-link to="/">Voltar Ao Menu</router-link>
      </v-btn>
    <!-- </div> -->
    </header>
    <div class="menuSearch" v-if="seen">
      <div class="items col jc-sb ai-c" @mouseleave="selected = 0">
        <div id="menuItem" @click="seen = !seen">
          <a
            class="jc-c ai-c"
            :class="selected == i + 1 ? 'active' : ''"
            @mouseover="selected = i + 1"
            :href="`#${item.id}`"
          >
            <div class="name">
              <div>{{ item.first }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      search: "",
      seen: false,
      selected: 0,
      items: [
        {
          first: "Editorial",
          id: "editorial"
        },
        {
          first: "Novo Plano de Inovação",
          id: "plano-estrategico"
        },
        {
          first: "Prémio Inovação 2020",
          id: "premio-inovacao"
        },
        {
          first: "FT Global Legal Hackathon",
          id: "legal-hackathon"
        },
        {
          first: "Portugal Digital Awards 2020",
          id: "digital-awards"
        },
        {
          first: "VdA Inc O Futuro Hoje",
          id: "incubadora"
        },
        {
          first: "VdA Accelerator",
          id: "accelerator"
        },
        {
          first: "Open Storms",
          id: "open-storms"
        },
        {
          first: "FT Innovative Lawyers 2020",
          id: "innovative-lawyers"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

header {
  width: 100vw;
  height: $navbar-height;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 1em 3em;
  background-color: white;
}

.nav-logo > *:not(:last-child) {
  margin-right: 18px;
}

.nav-logo p {
  // font-family: "Internacional-Black";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;
  text-transform: uppercase;
  color: $black-80;
  text-decoration: none;
}

.line {
  width: 1px;
  height: 28px;
  background: linear-gradient(0deg, #494949, #494949);
}

#back {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  width: 180px;
  height: 25px;
  background: white;
  border: none;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 6px 18px;
  text-decoration: none!important;
  background: url(./../assets/navbar/back.svg) no-repeat 96% center;
  // background: url(./../assets/premio-inovacao/previous-arrow.svg) no-repeat 96% center;
}

a {
  text-decoration: none;
  color: #838282;
  font-size: 16px}

.menuSearch {
  height: auto;
  top: 68px;
  right: 2vw;
  z-index: 105;
  position: fixed;
  background: #fff;
  padding: 20px 15px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  .items {
    width: 350px;
    height: auto;
    cursor: pointer;
    position: relative;
  }

  .name {
    position: absolute;
    text-align: left;
    width: auto;
    color: #000;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }

  .circle {
    height: 28px;
    width: 28px;
    border-radius: 50%;

    &-inner {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      // background: white;
      background: $bluegreengradient;
    }

    &.active {
      border: 2px solid $green;

      .circle-inner {
        background: $bluegreengradient;
      }
    }
  }
}

#menuItem {
  height: 30px;
}

/*REPONSIVE*/
@media only screen and (max-width: 600px) {
  .sidebar {
    display: none !important;
  }

  .w-50 {
    width: 100%;
  }

  #search {
    width: 100%;
    margin-bottom: 25px;
  }

  header {
    overflow-x: hidden;
    padding: 1em;
    text-align: center;
    flex-direction: column;
  }

  header p {
    margin-bottom: 25px;
  }

  .nav-logo {
    display: block !important;
  }

  .line {
    display: none;
  }

  .nav-logo img {
    margin-bottom: 25px;
  }
}
</style>
