<template>
  <div class="relative" @mouseover="hover = true" @mouseleave="hover = false">
    <section class="hackathon flex">
      <div class="hackathon-slider flex">
        <!-- SESSION 1 -->
        <div
          @mouseover="mouseOver(1)"
          @mouseleave="mouseLeave(1)"
          class="slide flex w-20 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
                <router-link to="card1">
                  <h3 class="video-title">
                    The Power <br />
                    Of The <br />
                    Law
                  </h3>
                  <video id="videoCard1" muted>
                    <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
                  </video>
                  </router-link>
            </div>
          </div>
        </div>

        <div
          @mouseover="mouseOver(2)"
          @mouseleave="mouseLeave(2)"
          class="slide flex w-30 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
              <router-link to="card1">
                <h3 class="video-title">
                  The Power <br />
                  Of The <br />
                  Law 1
                </h3>
                <video id="videoCard2" muted>
                  <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
                </video>
              </router-link>
            </div>
          </div>
        </div>

        <div
          @mouseover="mouseOver(3)"
          @mouseleave="mouseLeave(3)"
          class="slide flex w-30 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
              <router-link to="card1">
                <h3 class="video-title">
                  The Power <br />
                  Of The <br />
                  Law 2
                </h3>
                <video id="videoCard3" muted>
                  <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
                </video>
                </router-link>
            </div>
          </div>
        </div>

        <div
          @mouseover="mouseOver(4)"
          @mouseleave="mouseLeave(4)"
          class="slide flex w-30 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
              <router-link to="card1">
                <h3 class="video-title">
                  The Power <br />
                  Of The <br />
                  Law 3
                </h3>
                <video id="videoCard4" muted>
                  <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
                </video>
                </router-link>
            </div>
          </div>
        </div>

        <div
          @mouseover="mouseOver(5)"
          @mouseleave="mouseLeave(5)"
          class="slide flex w-30 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
              <router-link to="card1">
                <h3 class="video-title">
                  The Power <br />
                  Of The <br />
                  Law 4
                </h3>
                <video id="videoCard5" muted>
                  <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
                </video>
                </router-link>
            </div>
          </div>
        </div>

        <div
          @mouseover="mouseOver(6)"
          @mouseleave="mouseLeave(6)"
          class="slide flex w-30 h-30"
          :style="{ transform: `translateX(${value}%)` }"
        >
          <div class="w-30 flex ai-c">
            <div class="w-30">
              <h3 class="video-title">
                The Power <br />
                Of The <br />
                Law 5
              </h3>
              <video id="videoCard6" muted>
                <source src="@/assets/inicio/vertical.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div class="slide-control flex jc-sb ai-c">
        <div>
          <img
            src="@/assets/premio-inovacao/previous-arrow.svg"
            v-show="slide > 1"
            @click="translate('previous')"
          />
        </div>
        <div>
          <img
            src="@/assets/premio-inovacao/next-arrow.svg"
            v-show="slide < 1000"
            @click="translate('next')"
          />
        </div>
      </div>
      <!-- </div> -->
    </section>
    <!-- 
    <video autoplay muted loop id="inovVideo">
      <source
        src="./../assets/premio-inovacao/premio_inov.mp4"
        type="video/mp4"
      />
    </video> -->
  </div>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      // hover: false,
      open: false,
      slide: 1,
      value: -1
    };
  },
  methods: {
    mouseOver: function(id) {
      document.getElementById("videoCard" + id).play();
    },
    mouseLeave: function(id) {
      document.getElementById("videoCard" + id).pause();
    },
    translate(direction) {
      if (direction == "next" && this.slide < 4) {
        this.value = `-${this.slide}00`;

        this.slide++;
      } else if (direction == "previous" && this.slide > 0) {
        if (this.slide == 4) this.value = -200;
        else if (this.slide == 3) this.value = -100;
        else if (this.slide == 2) this.value = 0;

        this.slide--;
      }
    }
    // translate(direction) {
    //   if (direction == "next" && this.slide < 1000) {
    //     this.value = `-${this.slide}00`;
    //     this.slide++;

    //     console.log(this.value)
    //     console.log(this.slide)

    //   } else if(direction == "previous" && this.slide > 0) {
    //     if (this.slide == 1000) this.value = -200;
    //     else if (this.slide > 0 && this.slide < 1000){
    //     this.value = `-${this.slide}00`
    //     this.slide--;
    //     console.log(this.value)
    //     console.log(this.slide)
    //     }
    //   }
    // }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/main.scss";

section {
  background-color: black;
}

.premio-inovacao {
  // background-image: url(./../assets/premio-inovacao/bg-premio.png);

  img {
    position: absolute;
    bottom: 0;
    left: -12%;
    height: 90%;
  }

  div {
    position: absolute;
    bottom: 20%;
  }

  h1.small {
    font-size: 25px;
  }
}

.premio {
  &-col {
    margin: 15% 0 0 5%;
  }

  p:not(.toggle-text) {
    font-size: 18px;
    line-height: 30px;
    color: white;
    margin-bottom: 20px;
  }

  &-media img {
    width: 100%;
  }
}

video {
  height: 840px;
  margin-bottom:60px;
  z-index: -1;
  cursor: default;
  border-radius: 40px;
}

.toggle {
  p {
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    text-decoration-line: underline;
    color: $pink;
    margin-top: 20px;
  }
  &.open {
    background: url(./../assets/editorial/arrow-down.svg) no-repeat 96% center;
    width: 90px;
  }
  &.close {
    background: url(./../assets/editorial/arrow-up.svg) no-repeat 96% center;
    width: 100px;
  }
}

.hackathon {
  position: relative;

  h3,
  p {
    color: white;
    text-align: left;
  }

  h3 {
    font-family: "Internacional-Black";
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 54px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
  }

  h4 {
    font-family: Verdana;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 26px;
    color: $green;
  }

  p {
    font-size: 14px;
    line-height: 159%;
    // margin-bottom: 10px;
  }

  .nome {
    font-family: Internacional-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 350%;
  }

  &-slider {
    flex-wrap: nowrap;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slide {
    flex: 0 0 auto;
    transition: all 500ms ease-out 0s;
    -webkit-transition: all 500ms ease-out 0s;
    overflow-x: hidden;
    padding: 20px;

    .team {
      height: 80%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .ellipse {
      position: absolute;
      bottom: 5%;
      right: -4%;
      height: 110%;
    }

    &.last {
      .nome {
        font-family: Internacional-SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        // line-height: 70px; // 389%
      }

      .team {
        height: 80%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .ellipse {
        position: absolute;
        bottom: 5%;
        left: -4%;
        height: 110%;
      }
    }
  }

  .slide-control {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
  }
}

/*VIDEO*/
.relative {
  position: relative;
}

.premio-inovacao {
  z-index: 1;
  background-color: transparent;
}

/*ANIMAÇÕES*/
.revealed h1 {
  animation: fadeInUp;
  animation-duration: 2s;
}

.revealed p {
  animation: fadeInUp;
  animation-duration: 2s;
}

/*REPONSIVE*/
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 50px;
  }

  video {
    height: 500px;
    padding-left:10px
  }

  #videoCard {
    margin-left: 0px;
  }

  .w-100 {
    width: 100%;
    flex-direction: column;
  }

  .premio.flex {
    display: block;
  }

  .slide .w-50 {
    // width: auto !important;
  }

  .premio-col {
    // margin: 15% 0%;
  }

  .ellipse,
  .team {
    display: none !important;
  }

  .hackathon .slide-control {
    bottom: 10%;
  }
}
</style>
